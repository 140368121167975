import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '.';
import { Modal } from '../UI/modal';
import { PartiallyOptional, ToastMessage } from '../UI/toast';

type AppState = {
  modal: Modal | null;
  toastMessages: ToastMessage[];
};

const initialState: AppState = {
  modal: null,
  toastMessages: [],
};

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModal: (state, action: PayloadAction<Modal | null>) => {
      state.modal = action.payload;
    },
    dismissModal: (state) => {
      state.modal = null;
    },
    addToastMessage: (state, action: PayloadAction<PartiallyOptional<ToastMessage, 'id'>>) => {
      state.toastMessages = [
        { ...action.payload, id: Math.floor(Math.random() * Math.pow(9, 9) + 1) },
        ...state.toastMessages,
      ];
    },
    removeToastMessage: (state, action: PayloadAction<number>) => {
      state.toastMessages = state.toastMessages.filter((t) => t.id !== action.payload);
    },
  },
});

export const { setModal, dismissModal, addToastMessage, removeToastMessage } = appReducer.actions;

export const selectModal = (state: RootState) => state.app.modal;

export const selectToastMessages = (state: RootState) => state.app.toastMessages;

export default appReducer.reducer;
