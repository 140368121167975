import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './Pages/Error';
import ModalContainer from './UI/modal';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux';
import { PersistGate } from 'redux-persist/integration/react';
import { WagmiConfig, createClient } from 'wagmi';
import { getDefaultProvider } from 'ethers';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { ToastsContainer } from './UI/toast';
import { ComingSoon } from './Pages/ComingSoon';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '*',
        element: <ComingSoon />,
      },
      {
        path: '/',
        element: <ComingSoon />,
      },
      // {
      //   path: 'communities',
      //   element: <Communities />,
      // },
    ],
  },
]);

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <WagmiConfig client={client}>
        <RouterProvider router={router} />
        <ModalContainer />
        <ToastsContainer />
      </WagmiConfig>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
