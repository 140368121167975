import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../Redux';
import Toast from './toast';
import { selectToastMessages } from '../../Redux/appReducer';

export type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type ToastType = 'success' | 'error' | 'info';

export type ToastMessage = {
  id: number;
  type: ToastType;
  title: string;
  message: string;
  hideIcon?: boolean;
};

export const ToastsContainer = () => {
  const dispatch = useDispatch();
  const toasts = useAppSelector(selectToastMessages);
  return (
    <div className={`pointer-events-auto absolute right-0 top-0 w-full font-body`}>
      <ul>
        {toasts.map((toast) => {
          const { id } = toast;
          return <Toast {...toast} key={id} />;
        })}
      </ul>
    </div>
  );
};
