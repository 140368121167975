import './App.css';
import Header from './layout/Header';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useToast } from './Hooks/useToast';

function App() {
  const [copyButtonStatus, setCopyButtonStatus] = useState<string | null>();
  const toast = useToast();
  const [_, copyToClipboard] = useCopyToClipboard();
  const handleCopyToClipboard = () => {
    copyToClipboard('wooldox.eth');
    toast.success('', 'Copied!');
    setTimeout(() => {
      setCopyButtonStatus(copyButtonStatus);
    }, 1000);
  };
  return (
    <div className='h-[100vh] w-[100vw] font-body'>
      <Helmet>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      {/* <Header /> */}
      <main className='flex flex-col justify-center items-center h-screen'>
        <div className='relative h-full flex flex-col items-center justify-center'>
          <div className='font-pixel text-3xl cursor-pointer'>Ayanoa</div>
          <div className='flex items-center mt-4 text-gray-400'>Coming Soon</div>
        </div>
      </main>
      {/* <footer>
        <div className='max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-6xl 2xl:max-w-7xl lg:px-8 select-none'>
          <div className='border-t border-gray-800 py-8 text-sm text-gray-500 text-center sm:text-left'>
            <span className='block sm:inline'>
              Made by{' '}
              <a
                className='underline text-white/90'
                href='https://twitter.com/wooldox'
                target='_blank'
              >
                Wooldox
              </a>{' '}
              <div className='text-xs mt-1'>
                WOOL DONATIONS @{' '}
                <span className='text-white/90 cursor-pointer' onClick={handleCopyToClipboard}>
                  WOOLDOX.ETH
                </span>
              </div>
            </span>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

export default App;
